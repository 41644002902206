@import url("https://fonts.googleapis.com/css2?family=Balsamiq+Sans:ital,wght@1,700&family=Coming+Soon&family=Crafty+Girls&family=Lato:wght@300&family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Squada+One&family=Lato:wght@300&family=Rubik:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Dosis:wght@800&family=Dosis:wght@600&family=Quicksand:wght@300&family=Source+Sans+Pro:wght@300&display=swap");
@import "./styles.css";

a:-webkit-any-link {
  text-decoration: none;
  color: inherit;
}
#menuScrollTime {
  position: fixed;
  padding-top: 10px;
  background: #fff;
  z-index: 10;
  width: 100%;
  top: 0;
  border-bottom: 1px solid rgb(230, 230, 230);
}
.menuOptionsFixed {
  padding: 0;
  margin: 0 0 5px 0;
}
.menuOptionsFixed li {
  display: inline;
  font-family: "Lato", sans-serif;
  color: #646464;
  font-size: 16px;
  cursor: pointer;
  margin-right: 30px;
  padding-bottom: 16px;
}
.componentContainer {
  margin-top: 110px;
}
#socialIcons {
  margin-bottom: 70px;
}
#socialIcons div div i {
  color: #673ab7;
}
#socialIcons div div span {
  color: #3f51b5;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  text-align: center;
}
.socialsFollowMe {
  margin-top: 30px;
  color: #9c27b0;
  font-size: 19px;
  font-family: "Quicksand", sans-serif;
  text-align: center;
}
.followLinks {
  cursor: pointer;
}
.languageSelector {
  background: white;
  border: 0;
  border-radius: 10px;
  padding: 2px;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  background: #ebe9e9;
  cursor: pointer;
}
.welcomeText {
  font-family: "Raleway", sans-serif;
  font-size: 50px;
  text-align: center;
  color: #646464;
}
.welcomeDescription {
  margin: 30px auto 0;
  width: 400px;
  color: #45394b;
  font-family: "Raleway", sans-serif;
}
.welcomeDescriptionMobile {
  width: 100%;
  color: #45394b;
  font-family: "Raleway", sans-serif;
}
.rightImage {
  /* width: 100%; */
  height: 670px;
}
.introduceBig {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 45px;
  color: #858585;
}
.introduceDescription {
  font-family: "Raleway", sans-serif;

  color: #45394b;
}
.introduce-container {
  margin-top: 10%;
}
.ContactsGetInTouch {
  color: #6e6ced;
  font-size: 71px;
  text-align: center;
  font-family: "Rubik", sans-serif;
}
.ContactsGetInMobile {
  color: #6e6ced;
  font-size: 50px;
  text-align: center;
  font-family: "Rubik", sans-serif;
}
.input-container {
  display: flex;
  width: 100%;
  /* margin-bottom: 65px; */
  border-radius: 6px;
}
.input-field {
  width: 100%;
  padding: 10px 10px 10px 0;
  outline: none;
  border-top: 1px solid #bdbdbd;
  border-right: 1px solid #bdbdbd;
  border-bottom: 1px solid #bdbdbd;
  border-left: 0;
  background: #e0e0e0;
  color: black;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.icon {
  padding: 13px 0 10px 5px;
  color: #000000;
  min-width: 50px;
  text-align: center;
  background: #e0e0e0;
  border-left: 1px solid #bdbdbd;
  border-top: 1px solid #bdbdbd;
  border-bottom: 1px solid #bdbdbd;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
#ContactTextArea {
  width: 100%;
  border: 0;
  border-radius: 6px;
  background: #e0e0e0;
  color: black;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  padding: 10px;
  border: 1px solid #bdbdbd;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none; /*remove the resize handle on the bottom right*/
}
#ContactsCheckMark {
  color: #616161;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  line-height: 15px;
  margin: 0 0 0 10px;
}
#ContactsSubmitButton {
  width: 100%;
  margin: 0px auto;
  background: #673ab7;
  color: #e0e0e0;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 17px;
  padding: 17px 0;
  text-transform: uppercase;
}
#ContactsSubmitButton:hover {
  opacity: 0.8;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #000000;
  font-size: 15px;
}
::-webkit-placeholder {
  /* WebKit, Blink, Edge */
  color: #000000;
  font-size: 15px;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #000000;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #000000;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #000000;
}
.AbilitiesSection {
  background: #6d68f6;
  padding: 40px 0;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}
.myAbilitiesTable {
  width: 100%;
}
.myAbilitiesTitle {
  font-family: "Raleway", sans-serif;
  color: #fff;
  font-size: 36px;
  margin-bottom: 25px;
  margin-left: 45px;
}
.chevrons {
  color: white;
  cursor: pointer;
}
.myAbilitiesContent {
  overflow: auto;
  padding-bottom: 25px;
  /*  */
}
.abilitiesLanguagesTitle {
  font-family: "Lato", sans-serif;
  color: #fff;
  font-size: 60px;
  text-align: center;
}
.subjectImg {
  display: block;
  margin: 0 auto;
}
.languagesContainer {
  display: flex;
  flex-direction: row;
}
.languageContainerParent {
  width: 220px;
  margin-top: 120px;
  margin-left: auto;
  margin-right: auto;
}
#abilitiesLanguage {
  color: #fff;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  margin-left: 13px;
}
.Home {
  overflow-x: hidden;
}
@media only screen and (max-width: 600px) {
  .myAbilitiesTitle {
    margin-left: 0;
    text-align: center;
  }
}
