input:focus {
  outline: none;
}
.mobileNavbarContainer {
  display: flex;
  position: fixed;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  top: 0;
  background-color: #fff;
  border-bottom: 1px solid #cecece;
  z-index: 10;
}
.mobileNavbarContainer .title {
  color: #673ab7;
  cursor: pointer;
  margin-left: 10px;
  position: relative;
  top: 5px;
  font-family: "Dosis", sans-serif;
  font-size: 18px;
  font-weight: 800;
}
#mobileMenuButton {
  display: inline-block;
  cursor: pointer;
}
#mobileMenuButton div {
  width: 35px;
  height: 5px;
  background-color: #673ab7;
  margin: 6px 0;
  transition: 0.4s;
}
.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}
.change .bar2 {
  opacity: 0;
}
.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}
.mobileMenuContent {
  position: fixed;
  top: 57px;
  z-index: 10;
  background: #fff;
  box-shadow: 0 10px 5px #673ab7;
  width: 350px;
  height: 100%;
  left: -355px;
  transition: 0.3s;
}
.mobileMenuContent span {
  text-decoration: underline;
  color: #673ab7;
  cursor: pointer;
  margin: 0 10px;
}
.mobileMenuContent ul {
  padding-left: 15px;
  padding-right: 15px;
}
.mobileMenuContent ul li {
  list-style: none;
  background: url(../assets/arrow.png) no-repeat center right;
  background-size: 10px;
  padding: 5px 10px;
  border-bottom: 1px solid #979797;
  font-family: "Dosis", sans-serif;
  font-size: 17px;
  color: #673ab7;
  cursor: pointer;
  font-weight: 600;
}
.Projects .recentyDoneProjects {
  font-size: 50px;
  font-family: "Source Sans Pro", sans-serif;
  color: #858585;
}
.Projects .optionButtonsContainer {
  font-family: "Raleway", sans-serif;
  color: #673ab7;
  cursor: pointer;
}
.Projects .optionButtonsContainer ul {
  margin: 0 0 20px 0;
  padding: 0;
}
.Projects .optionButtonsContainer li {
  display: inline;
  margin: 0 30px 0 0;
}
.Projects .optionButtonsContainer ul li button span {
  margin-left: 10px;
}
.Projects .optionButtonsContainer .buttonBordered {
  border: 1px solid #0d72fd;
}
.Projects .optionButtonsContainer .btn:focus {
  box-shadow: none;
}
.Projects .ProjectCell .projectImage {
  filter: brightness(85%);
  border-radius: 4px;
  cursor: pointer;
}
.Projects .ProjectCell .projectImage:hover {
  filter: brightness(93%);
  border-radius: 5px;
  box-shadow: 0 10px 20px #858585;
  transform: scale(1.1);
  transition: 0.3s;
}
.Projects .ProjectCell .title {
  margin: 10px 0 5px 0;
  font-family: "Squada One", cursive;
  font-size: 23px;
  color: #858585;
  text-transform: uppercase;
  cursor: pointer;
}
.Projects .ProjectCell .title span {
  margin-left: 8px;
}
.Projects .ProjectCell .description {
  font-size: 15px;
  color: #858585;
  font-family: "Source Sans Pro", sans-serif;
}
.liBordered {
  color: #3f51b5 !important;
  font-weight: bold;
  border-bottom: 2px solid #3f51b5;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background: #673ab7;
  border-radius: 50px;
}
.free-wifi {
  display: block;
  width: 130px;
}
.wifi-form-container {
  border: 1px solid #bdbdbd;
  background-color: #f5f5f5;
  border-radius: 5px;
}
.wifi-form-container .wifi_fill {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  text-align: center;
}
.wifi-form-container .wifi_input_wrap {
  display: flex;
  flex-direction: row;
  border: 1px solid #bdbdbd;
  background-color: #fff;
  border-radius: 4px;
  height: 40px;
}
.wifi-form-container .wifi_input_wrap input {
  width: 92%;
  border: 0;
  border-radius: 4px;
  color: #757575;
  font-family: "Source Sans Pro", sans-serif;
}
.wifi-form-container .wifi_input_wrap i {
  width: 8%;
  display: block;
  text-align: center;
  color: #757575;
}
.wifi-form-container button {
  border: 0;
  width: 100%;
  background-color: #673ab7;
  color: #fff;
  height: 40px;
  font-family: "Source Sans Pro", sans-serif;
  border-radius: 4px;
}
.wifi-form-container .wifi_pass {
  font-size: 30px;
  background: inherit;
}
.wifi-form-container button:hover {
  opacity: 0.9;
}
